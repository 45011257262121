import cookie from 'cookie';
import {OrganizationJsonLd} from 'next-seo';
import {Interceptor} from '@jobseeker/common-core/lib/components/Inteceptor';
import {wrapper} from '@jobseeker/common-core/lib/stores';
import loadAds from '@jobseeker/common-core/lib/helpers/loadAds';
import {COOKIE_LOGIN_AUTH} from '@jobseeker/common-core/lib/constants';
import {gladiatorService} from '@jobseeker/common-core/lib/services';

import Home from '@components/Home';
import NavLayout from '@layouts/NavLayout';
import {SeoLayout} from '@layouts/SeoLayout';

import {
  getConfigByApp,
  isUserAgentForBot,
  removedUndefinedFromArrayObject,
  utilServerSideDeviceDetection,
} from '@utils';
import {metaInfo} from '@configs';
import {HOME, ROUTE_JOBFEED} from '@constants';
import {loadSEO} from '@helpers';

import type {BaseContext} from 'next/dist/shared/lib/utils';
import {getCookiesToDelete, setSSRCookies} from '@helpers/ssrMap';
import {TrackEventUBA} from '@jobseeker/common-core/lib/utils';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

type HomePageProps = {
  seoConfig: object;
  enableCSR: boolean;
  metaData: object;
};

const HomePage = ({seoConfig, metaData}: HomePageProps) => {
  const router = useRouter();
  useEffect(() => {
    TrackEventUBA('homeWithoutLoginPageView', 'view', {
      ref: router.query.ref || '',
      pageName: 'Home Page',
      sessionUsername: `${sessionStorage.getItem('userId')}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Interceptor metaInfo={metaData}>
      <SeoLayout seoConfig={seoConfig} />
      <OrganizationJsonLd
        name={`${process.env.NEXT_PUBLIC_APP_NAME}.com` || 'iimjobs.com'}
        url={`https://www.${process.env.NEXT_PUBLIC_APP_NAME}.com`}
        logo={'https://static.iimjobs.com/resources/images/iimjobs200x200.png'}
        sameAs={[
          'https://www.facebook.com/iimjobs',
          'https://www.linkedin.com/company/iimjobs.com',
          'https://twitter.com/iimjobs',
        ]}
      />
      <NavLayout page={HOME}>
        <Home />
      </NavLayout>
    </Interceptor>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  store => async (context: BaseContext) => {
    store.dispatch({
      type: 'AppContext/set_current_page',
      payload: {page: HOME},
    });

    const isBotUser = isUserAgentForBot(context);

    console.log('🚀 ~ file: index.tsx:54 ~ store=> ~ isBotUser:', isBotUser);

    const seoConfig = await loadSEO(HOME);
    const metaData = getConfigByApp(metaInfo, HOME, true, 'homepage');

    const {isMobile} = utilServerSideDeviceDetection(context);
    console.log('🚀 ~ store=> ~ Home Page --> isMobile:', isMobile);
    if (isMobile) {
      store.dispatch({
        type: 'AppContext/set_layout_device',
        payload: 'mobile',
      });
    }

    try {
      if (context.req?.headers?.cookie) {
        const cookies = cookie.parse(context.req.headers.cookie);
        console.log(
          '🚀 ~ file: index.tsx:40 ~ store=> ~ cookies:',
          context.req?.headers
        );

        if (cookies?.[COOKIE_LOGIN_AUTH]) {
          const validateInfo = await gladiatorService.validate('validate', {
            headers: {
              cookie: context.req?.headers?.cookie,
            },
          });
          console.log(
            '🚀 ~ file: index.tsx:56 ~ store=> ~ validateInfo:',
            validateInfo,
            typeof validateInfo
          );

          if (
            validateInfo?.type &&
            ['old', 'refresh'].includes(validateInfo.type) &&
            validateInfo?.token
          ) {
            const {ssrLastActCookie, ssrTokenCookie} = setSSRCookies(
              validateInfo.token
            );

            context.res?.setHeader('Set-Cookie', [
              ssrTokenCookie,
              ssrLastActCookie,
            ]);

            return {
              redirect: {
                permanent: false,
                destination: ROUTE_JOBFEED,
              },
            };
          } else if (validateInfo?.type === 'logout') {
            const cookiesTodelete = getCookiesToDelete();
            context.res?.setHeader('Set-Cookie', cookiesTodelete);
          } else if (validateInfo?.token) {
            return {
              redirect: {
                permanent: false,
                destination: ROUTE_JOBFEED,
              },
            };
          }
        }
      }
    } catch (error: /* eslint-disable @typescript-eslint/no-explicit-any */ any) {
      console.log('🚀 ~ file: index.tsx:71 ~ store=> ~ validate error:', error);

      const token = await gladiatorService.refresh('refresh', {
        headers: {
          cookie: context.req?.headers?.cookie,
        },
      });

      if (token) {
        const {ssrLastActCookie, ssrTokenCookie} = setSSRCookies(token);

        context.res?.setHeader('Set-Cookie', [
          ssrTokenCookie,
          ssrLastActCookie,
        ]);

        console.log('🚀 ~ file: index.tsx:68 ~ store=> ~ token:', token);

        return {
          redirect: {
            permanent: false,
            destination: ROUTE_JOBFEED,
          },
        };
      }
    }

    try {
      const {
        home: {
          section: {
            featuredEmployer,
            companyWorkWith,
            enhanceSkills,
            learningPartner,
          },
        },
      }: /* eslint-disable @typescript-eslint/no-explicit-any */ any =
        metaData || {};

      if (featuredEmployer?.slotId) {
        const ads = await loadAds(featuredEmployer.slotId);

        if (ads) {
          store.dispatch({
            type: 'Ads/set_featured_employer',
            payload: removedUndefinedFromArrayObject(ads),
          });
        }
      }

      if (!isBotUser) {
        return {
          props: {
            enableCSR: true,
            seoConfig,
            metaData,
          },
        };
      }

      if (companyWorkWith?.slotId) {
        const ads = await loadAds(companyWorkWith.slotId);

        if (ads) {
          store.dispatch({
            type: 'Ads/set_company_work_with',
            payload: removedUndefinedFromArrayObject(ads),
          });
        }
      }

      if (enhanceSkills?.slotId) {
        const ads = await loadAds(enhanceSkills.slotId);

        if (ads) {
          store.dispatch({
            type: 'Ads/set_enhance_skills',
            payload: removedUndefinedFromArrayObject(ads),
          });
        }
      }

      if (learningPartner?.slotId) {
        const ads = await loadAds(learningPartner.slotId);

        if (ads) {
          store.dispatch({
            type: 'Ads/set_learning_partner',
            payload: removedUndefinedFromArrayObject(ads),
          });
        }
      }

      return {
        props: {
          seoConfig,
          metaData,
        },
      };
    } catch (error) {
      console.log(`🚀 ~ SSR ~ /${HOME}=> ~ ERROR:`, error);

      return {
        props: {
          seoConfig,
          metaData,
        },
      };
    }
  }
);

export default HomePage;
